<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <!--<b-form-group id="input-group-1" label="OID" label-for="input-1">
          <b-form-input
            id="input-1"
            :value="form.oid"
            placeholder="OID"
            disabled
          ></b-form-input>
        </b-form-group>-->

          <b-form>
            <b-form-group
              id="input-group-2"
              label="Название"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Путь" label-for="input-3">
              <b-form-select
                v-model="form.route_oid"
                :options="routesOptions"
                id="input-3"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Параметры"
              label-for="input-4"
            >
              <b-form-textarea
                v-model="form.params"
                id="input-20"
                :rows="5"
                size="lg"
                placeholder="Параметры"
              />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
    };
  },

  computed: {
    ...mapGetters(['routes']),
    routesOptions() {
      return this.routes.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: 'Сохранено!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
