<template>
  <div
    v-if="form !== null"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container-fluid"
  >
    <div class="width-100">
      <action-form v-model="form" ref="actionForm" />

      <div class="justify-content-end d-flex bg-white tabs-footer">
        <button
          ref="dealerSubmit"
          type="button"
          class="btn btn-success font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
          @click.prevent="saveExport"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
  SET_BREADCRUMB_ROUTE_BACK,
} from '@/core/services/store/breadcrumbs.module';
import { GET_ITEM } from '@/core/services/store/actions.module';
import ActionForm from '@/view/content/forms/ActionForm';

export default {
  components: { ActionForm },

  data() {
    return {
      form: null,
    };
  },

  async beforeMount() {
    const id = this.$route.params?.id;
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, true);
    this.$store.commit(SET_BREADCRUMB_ROUTE_BACK, { name: 'general-actions' });
    if (id) {
      this.form = await this.$store.dispatch(GET_ITEM, id);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Экшены"', route: { name: 'general-actions' } },
        { title: this.form?.name },
      ]);
    } else {
      throw Error('Not id');
    }
  },

  methods: {
    saveExport() {
      this.$refs?.actionForm?.submit();
    },
  },
};
</script>
